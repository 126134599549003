@import '../../vendor/styles/variables.css';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;

  &--center {
    padding: 0 16px;
  }

  &__dialog {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 4px;

    &--center {
      height: auto;
    }

    &--bottom {
      position: fixed;
      height: auto;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &--top {
      position: fixed;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      border-radius: 0px;
    }
  }

  &__header {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    min-height: 56px;

    &-cont {
      padding: 12px 16px 12px 0;
      flex: 1;
    }

    & .heading-1 {
      margin: 0;
    }
  }

  &__body {
    flex: 1 1 auto;

    /* smooth scrolling */
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }

  /* Needed for smooth scroll in ios */
  &__ios-hack-cont {
    max-height: 100%;
  }

  &__footer {
    flex: 0 0 auto;
  }

  &--open {
    overflow: hidden;
  }

  &__icon {
    color: var(--color-secondary);
    /* padding: 18px 16px; */
  }
}
