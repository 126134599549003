@import '../../../../vendor/styles/variables.css';

.el {
  &__title {
    font-weight: 300;
    color: var(--color-secondary);
  }

  &__container {
    margin-top: 16px;
  }

  &__use-mobile {
    color: var(--color-link);
    font-size: 12px;
    text-align: right;
    font-weight: 500;
  }

  &__text {
    font-size: 12px;
    color: var(--color-tertiary);
    font-weight: 400;
  }

  &__requestotp {
    margin-bottom: 19px;
    margin-top: 28px;
    &:disabled {
      background-color: #d9d9d9;
      border-color: #ffffff;
    }
  }
}
