@import '../../../../vendor/styles/variables.css';

.ml {
  &__title {
    font-weight: 300;
    color: var(--color-secondary);
  }

  &__container {
    margin-top: 16px;
  }

  &__requestotp {
    margin-bottom: 19px;
    margin-top: 28px;
    &:disabled {
      background-color: #d9d9d9;
      border-color: #ffffff;
      font-weight: 700;
      font: size 14px;
    }
  }

  &__error {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 48px;
    width: 100%;
    background-color: var(--color-quaternary);

    &__text {
      line-height: 48px;
      vertical-align: middle;
      color: var(--color-secondary);
    }
  }

  &__input input {
    padding-left: 73px;
  }
}
