.login_interrupt_emphasized_text{
    font-weight:700;
    color: #ffca0a;
    padding: 0;
    margin: 0;
    font-size: 16px;
  }

  .login_interrupt_container{
    border-radius: 8px;
  }